import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../components/images/imageCoaching.js'
import ImageDevelopement from '../components/images/imageDevelopement.js'
import ImageClimat from '../components/images/imageClimat.js'
import ImageChange from '../components/images/imageChange.js'
import ImageConflit from '../components/images/imageConflit.js'
import ImageLeft1 from '../components/images/imageLeft1.js'

import Logo1 from '../images/logo1.png'
import Logo2 from '../images/logo16.png'
import Logo3 from '../images/logo3.png'
import Logo4 from '../images/logo4.png'
import Logo5 from '../images/logo5.png'
import Logo6 from '../images/logo6.png'
import Logo7 from '../images/logo7.png'
import Logo8 from '../images/logo8.png'
import Logo9 from '../images/logo9.png'
import Logo10 from '../images/logo10.png'
import Logo11 from '../images/logo11.png'
import Logo12 from '../images/logo12.png'
import Logo13 from '../images/logo13.png'
import Logo14 from '../images/logo14.png'
import Logo15 from '../images/logo15.png'

import Logo17 from '../images/logo17.png'
import Logo18 from '../images/logo18.png'
import Logo19 from '../images/logo19.png'
import Logo20 from '../images/logo20.png'
import Logo21 from '../images/logo21.png'
import Logo22 from '../images/logo22.png'
import Logo23 from '../images/logo23.png'
import Logo24 from '../images/logo24.png'
import Logo25 from '../images/logo25.png'
import Logo26 from '../images/logo26.png'
import Logo27 from '../images/logo27.png'
import Logo28 from '../images/logo28.png'
import Logo29 from '../images/logo29.png'
import Logo30 from '../images/logo30.png'
import Logo31 from '../images/logo31.png'
import Logo32 from '../images/logo32.png'
import Logo33 from '../images/logo33.png'
import Logo34 from '../images/logo34.png'

const NosClients = () => (
  <Layout>
    <SEO title="Nos Clients | Gestion Mieux-Être" />
    <PageHeader
        header="Nos Clients"
        text="Nous accompagnons autant les grandes que les petites et moyennes entreprises, les organismes publics et parapublics ainsi que les associations professionnelles. "
        quote="Quoique tu rêves d’entreprendre, commence-le <span>- Goethe</span>"
        background="mieuxetre" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Des solutions innovantes pour nos clients :</p>
        <div className="flex-container2 tcenter pad2">
                <img class="logo" src={Logo2} />
                <img class="logo" src={Logo13} />
                <img class="logo" src={Logo6} />
                <img class="logo" src={Logo14} />
                <img class="logo" src={Logo1} />
                <img class="logo" src={Logo17} />
                <img class="logo" src={Logo9} />
                <img class="logo" src={Logo18} />
                <img class="logo" src={Logo7} />
                <img class="logo" src={Logo5} />
                <img class="logo" src={Logo4} />
                <img class="logo" src={Logo19} />
                <img class="logo" src={Logo11} />
                <img class="logo" src={Logo20} />
                <img class="logo" src={Logo15} />
                <img class="logo" src={Logo21} />
                <img class="logo" src={Logo22} />
                <img class="logo" src={Logo23} />
                <img class="logo" src={Logo3} />
                <img class="logo" src={Logo25} />
                <img class="logo" src={Logo26} />
                <img class="logo" src={Logo27} />
                <img class="logo" src={Logo12} />
                <img class="logo" src={Logo10} />
                <img class="logo" src={Logo8} />
                <img class="logo" src={Logo28} />
                <img class="logo" src={Logo29} />
                <img class="logo" src={Logo30} />
                <img class="logo" src={Logo31} />
                <img class="logo" src={Logo32} />
                <img class="logo" src={Logo33} />
                <img class="logo" src={Logo34} />




        </div>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        <br /><br /><br />
        <br /><br /><br />
        </ScrollAnimation>
    </div>
  </Layout>
)

export default NosClients
